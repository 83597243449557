import { render, staticRenderFns } from "./PPet.vue?vue&type=template&id=00588baa&scoped=true&"
import script from "./PPet.vue?vue&type=script&lang=js&"
export * from "./PPet.vue?vue&type=script&lang=js&"
import style0 from "./PPet.vue?vue&type=style&index=0&id=00588baa&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00588baa",
  null
  
)

export default component.exports