<template>
  <div class="page-storage">
    <img v-for="(image, i) in images" :key="i" :src="image" alt="" :style="i == activeIndex ? 'z-index: 99' : ''" @click="onClick" />
    <!-- <ul class="pagination">
      <li v-for="(image, i) in images" :key="i" :class="{ active: i == activeIndex }"></li>
    </ul> -->
  </div>
</template>

<script>
export default {
  data: () => ({
    activeIndex: 0,
    images: [require('../../assets/images/com/storage-1.jpg'), require('../../assets/images/com/storage-2.jpg'), require('../../assets/images/com/storage-3.jpg')],
    timer: null,
    }),
  methods: {
    onClick() {
      // this.next()
    },
    prev() {
      this.activeIndex = this.activeIndex > 0 ? this.activeIndex - 1 : this.images.length - 1
    },
    next() {
      this.activeIndex = this.activeIndex < this.images.length - 1 ? this.activeIndex + 1 : 0
    },
  },
  mounted() {
    clearInterval(this.timer)
    this.timer = setInterval(() => {
      this.next()
    }, 1500);
  }
}
</script>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
}
.page-storage {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
  }
  .pagination {
    position: absolute;
    box-sizing: border-box;
    right: 42px;
    bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;

    li {
      box-sizing: border-box;
      width: 16px;
      height: 16px;
      border: 2px solid black;
      border-radius: 50%;
      transition: 0.25s ease-out;
      margin-left: 12px;
      background-color: white;
      &.active {
        background-color: #f8917c;
      }
    }
  }
}
</style>
