<template>
  <header class="header">
    <img class="header-logo" src="../../assets/images/logo.png" alt="" />
    <div class="header-title" v-if="!disableTitle">武汉小咪网络科技有限公司</div>
    <div class="spacer"></div>
    <ul class="header-menu">
      <li class="header-menu-item" :class="{ active: index == 0, 'is-baidu': item.id == 2 && isBaidu, 'is-qihu': item.id == 2 && isQihu }" v-for="(item, index) in menu" :key="index" @click="onMenuClick(item)">
        {{ item.name == '首页' && disableTitle ? '' : item.name }}
      </li>
    </ul>
  </header>
</template>

<script>
export default {
  props: {
    channel: {
      type: null,
      default: 0
    },
    disableTitle: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    self: null,
    menu: [
      {
        id: 1,
        name: '首页',
      },
      {
        id: 2,
        name: 'PC端下载',
      },
    ],
  }),
  computed: {
    isBaidu() {
      return this.channel == 20210426043
    },
    isQihu() {
      return this.channel == 20200707006
    },
  },
  methods: {
    onMenuClick(item) {
      switch (item.name) {
        case '首页':
          _czc.push(['_trackEvent', '落地页 ', '点击', '首页按扭'])
          window.location.href = '/'
          break
        case 'PC端下载':
          this.$emit('download')
          break
        default:
          break
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  height: 84px;
  font-family: 'Microsoft YaHei';
  padding: 0 100px;
  .header-title {
    margin: 0 22px;
    font-size: 16px;
    color: black;
    letter-spacing: 3px;
  }
}
.spacer {
  flex: 1;
}
.header-menu {
  display: flex;
  align-items: center;
  .header-menu-item {
    font-size: 18px;
    font-weight: bold;
    color: #111010;
    padding: 0 27px;
    cursor: pointer;
  }
}
</style>
