<template>
  <div class="page-pet">
    <div class="pet"></div>
    <div class="pet-message-1"></div>
    <div class="pet-message-2"></div>
    <div class="pet-message-3"></div>
    <div class="pet-message-4"></div>
  </div>
</template>

<script>
export default {
  data: () => ({

  }),
  methods: {
    
  }
}
</script>

<style lang="scss" scoped>
.page-pet {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/images/com/pet-bg.png');

  * {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}
.pet {

  position: absolute;
  left: 50%;
  top: 48px;
  transform: translateX(-50%);
  width: 260px;
  height: 314px;
  background-image: url('../../assets/images/com/pet.gif');
}
.pet-message-1 {
  position: absolute;
  right: 14px;
  top: 92px;
  width: 200px;
  height: 36px;
  background-image: url('../../assets/images/com/pet-2.png');
  animation: float 4s infinite;
}

.pet-message-2 {
  position: absolute;
  left: 12px;
  top: 136px;
  width: 206px;
  height: 36px;
  background-image: url('../../assets/images/com/pet-1.png');
  animation: float 3s infinite;
}

.pet-message-3 {
  position: absolute;
  right: 26px;
  top: 262px;
  width: 184px;
  height: 36px;
  background-image: url('../../assets/images/com/pet-3.png');
  animation: float 4s infinite;
}

.pet-message-4 {
  position: absolute;
  left: 84px;
  top: 282px;
  width: 74px;
  height: 34px;
  background-image: url('../../assets/images/com/pet-4.png');
  animation: float 3s infinite;
}
@keyframes float {
  0% {
    transform: translateY(10%);
  }
  50% {
    transform: translateY(-10%);
  }
  100% {
    
    transform: translateY(10%);
  }
}
</style>