import request from '@/axios/index.js';

//精选壁纸
export function apiBanner(query) {
  return request({
    headers: {
      port: 9999
    },
    url: '/official/landing_page/banner',
    method: 'POST',
    data: query
  })
}

//精选壁纸
export function apiSelected(query) {
  return request({
    headers: {
      port: 9999
    },
    url: '/official/landing_page/selected',
    method: 'POST',
    data: query
  })
}

//精选壁纸
export function apiClassication(query) {
  return request({
    headers: {
      port: 9999
    },
    url: '/official/landing_page/classification',
    method: 'POST',
    data: query
  })
}

//精选壁纸
export function apiBeautify(query) {
  return request({
    headers: {
      port: 9999
    },
    url: '/official/landing_page/beautify',
    method: 'POST',
    data: query
  })
}


//pc版本信息
export function apiDownload(query) {
  return request({
    headers: {
      port: 9999
    },
    url: '/official/qr_code/official',
    method: 'get',
    params: query
  })
}
